import Vue from 'vue'
import Router from 'vue-router'  // 引入定义好的router
import Index from '../views/index.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index,
      // redirect:'/',
      children:[
        {
          path: '/',
          name: 'home',
          component: () => import(/* webpackChunkName: 'home' */ '@/views/home/index.vue')
        },
        {
          path: '/transparent',
          name: 'transparent',
          component: () => import(/* webpackChunkName: 'home' */ '@/views/transparent/index.vue')
        },
        {
          path: '/publicity',
          name: 'publicity',
          component: () => import(/* webpackChunkName: 'home' */ '@/views/publicity/index.vue')
        },{
          path: '/aboutUs',
          name: 'aboutUs',
          component: () => import(/* webpackChunkName: 'home' */ '@/views/aboutUs/index.vue')
        },{
          path: '/blacklist',
          name: 'blacklist',
          component: () => import(/* webpackChunkName: 'home' */ '@/views/blacklist/index.vue')
        },{
          path: '/detail',
          name: 'detail',
          component: () => import(/* webpackChunkName: 'home' */ '@/views/detail/index.vue')
        }
      ]
    },
    
  ]
})

